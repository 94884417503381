.react-datepicker-popper {
  z-index: 1337;
}

.react-datepicker__header {
  background: transparent;
  border: 0;
}

.react-datepicker__triangle {
  border-color: white;
}

.react-datepicker-time__header,
.react-datepicker__current-month {
  font-weight: normal;
  padding: 6px;
}

.react-datepicker__navigation {
  top: 17px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 96px;
}

.react-datepicker__day-names {
  padding-top: 8px;
  font-size: 0.9em;
}

.react-datepicker__day-names .react-datepicker__day-name {
  color: #6b6b6b;

}

.react-datepicker__day:hover,
.react-datepicker__day:focus,
.react-datepicker__day--selected:focus,
.react-datepicker__day--selected:hover {
  border-radius: 2rem;
}

.react-datepicker__day--highlighted,
.react-datepicker__day--highlighted:focus,
.react-datepicker__day--highlighted:hover {
  border-radius: 2rem;
  background: rgba(91, 163, 137, 0.15);
  color: #6b6b6b;
}

.react-datepicker__day--disabled-holiday,
.react-datepicker__day--disabled-holiday:focus,
.react-datepicker__day--disabled-holiday:hover {
  border-radius: 2rem;
  background: rgba(251, 80, 50, 0.15);
  color: #6b6b6b;
  pointer-events: none; 
}

.react-datepicker__day--disabled:not(.react-datepicker__day--disabled-holiday) {
  background-color: transparent !important;
  color: #b3b3b3 !important;
  pointer-events: none;
}

.react-datepicker__day--selected {
  border-radius: 2rem;
  background-color: #1975d2;
  color: #ffffff;
}

.react-datepicker__time-list-item {
  line-height: 30px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #1975d2;
}

.react-datepicker__day {
  padding: 4px;
  font-size: 0.9rem;
}

.react-datepicker {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  border: 0;
}

.react-datepicker__time-container {
  border-left-color: #e0e0e0;
  margin-left: -1px;
}

.react-datepicker__month-container {
  border-right: 1px solid #e0e0e0;
}

.react-datepicker.calendar-inline {
  box-shadow: none;
  border: 1px solid #e6e6e6;
}

.calendar-inline .react-datepicker__month-container {
  border-right: 0;
}

.react-datepicker__input-container > div {
  width: 100%;
}
