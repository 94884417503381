@import './src/lib/themes/colors.module.scss';
@import './src/components/form/tree-select/tree-select.scss';
// audit update sprint 8 2023 not sure if these are needed -AM
// TODO: Remove antd
@import '../node_modules/antd/es/tree-select/style/index.css';
@import '../node_modules/antd/es/select/style/index.css';
@import '../node_modules/antd/es/empty/style/index.css';

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Gotham SSm A', 'Gotham SSm B', Verdana, Geneva, Tahoma,
    sans-serif;
  color: $formItem;
  font-weight: 300;
  font-size: 0.875rem;
}

.rc-mentions {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;
  width: 100%
}
.rc-mentions > textarea,
.rc-mentions-measure {
  font-size: inherit;
  font-size-adjust: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  line-height: inherit;
  vertical-align: top;
  overflow: inherit;
  word-break: inherit;
  word-wrap: break-word;
  overflow-x: initial;
  overflow-y: auto;
  text-align: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  tab-size: inherit;
  direction: inherit;
}
.rc-mentions > textarea {
  display: block;
  width: 100%;
  margin: 0px;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  letter-spacing: inherit;
  height: 100%;
  bottom: 0px;
  overflow: auto;
  resize: none;
  color: rgb(68, 72, 76);
  font-weight: 300;
  padding: 9px;
  outline: 0px;
  border: 0px;
}
.rc-mentions-measure {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  color: transparent;
  z-index: -1;
}
.rc-mentions-dropdown {
  position: absolute;
  z-index: 10000;
}
.rc-mentions-dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rc-mentions-dropdown-menu-item {
  cursor: pointer;
}
.rc-mentions {
  font-size: 20px;
  border: 1px solid #999;
  border-radius: 3px;
  overflow: hidden;
}
.rc-mentions-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  background: #FFF;
}
.rc-mentions-dropdown-menu-item {
  padding: 4px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.rc-mentions-dropdown-menu-item-active {
  background: #CEE4E5;
}
.rc-mentions-dropdown-menu-item-disabled {
  opacity: 0.5;
}
