// colors.module.scss
//
// All hex-values should first be assigned to a descriptive variable,
// then to functional variables.
// Exports can be used to share variables with JavaScript,
// for example, when configuring the material-ui theme

// Descriptive Variables
// _____________________

// Brand Colors
$trellisBlue: #547096;
$trellisLightBlue: #eef4f9;
$cuttySark: #3b7b5f;
$patina: #5ba389;
$teal: $trellisBlue;
$patinaWhite: #f0fff9;
$patinaWhite4: #cee4e5;
$bluesky2: #cad3de;
$bluesky: #e9f4ff;
$deepBlue: #456189;
$deepBlue2: #547096;
$bismark: #446289;
$cornflowerblue: #dee7ef;

// Grayscale
$white: #ffffff;
$offWhite: #ededed;
$offWhite2: #e0e0e0;
$grey2: #edefef;
$grey3: #eef3f6;
$grey12: #70777a;
$grey13: #d9e0e2;
$grey14: #f7f7f7;
$grey23: #c0c0c0;
$grey25: #e1e1e1;
$lightGrey: #a3a3a3;
$steelGrey: #44484c;
$pearlGrey: #d1dce6;
$adherenceIntHistoryTitleGrey: #464448;
$adherenceIntHistoryBorderGrey: #d6d7d7;
$timberWolf: #979797;
$silverMist: #d2d2d2;
$black: #000;
$greyBlue: #8ea6bc;
$greyLightBlue: #e4ecf3;
$greySubRow: #f2f4f4;
$rollingStone: #70777a;

// Accent Colors
$yellow: #e0bb53;
$yellow2: #edcc7c;
$yellow3: #f9efd7;
$valenciaRed: #d94c44;
$deepChestnutRed: #c04e4a;
$darkRed: #b5302c;
$lightRed: #dd705e;
$flaggedRed: #fbf0ed;
$redWhite: #fff1ef;
$lightGreen: #edfff8;
$orange: #ff7e00;

// Functional Variables
// ____________________

$main: $trellisBlue;
$pageHeader: $cuttySark;
$formLabel: $grey12;
$formItem: $steelGrey;
$faintBorder: $grey13;
$background: $white;
$summaryPanel: $offWhite;
$contrastText: $white;
$hover: $patina;
$success: $patina;
$positive: $patina;
$warning: $yellow;
$error: $valenciaRed;
$important: $valenciaRed;
$addBtnColor: $lightGrey;
$taskTypeLabelOverdue: $lightRed;
$taskTypeLabelComplete: $teal;
$taskTypeLabelIncomplete: $lightGrey;
$trellisBlue: $trellisBlue;

// Exports
// _______

:export {
  main: $main;
  pageHeader: $pageHeader;
  cuttySark: $cuttySark;
  faintBorder: $faintBorder;
  formLabel: $formLabel;
  formItem: $formItem;
  background: $background;
  white: $white;
  steelGrey: $steelGrey;
  black: $black;
  summaryPanel: $summaryPanel;
  contrastText: $contrastText;
  hover: $hover;
  success: $success;
  positive: $positive;
  warning: $warning;
  error: $error;
  important: $important;
  lightRed: $lightRed;
  flaggedRed: $flaggedRed;
  darkRed: $darkRed;
  yellow: $yellow;
  yellow2: $yellow2;
  yellow3: $yellow3;
  addBtnColor: $addBtnColor;
  taskTypeLabelOverdue: $taskTypeLabelOverdue;
  taskTypeLabelComplete: $taskTypeLabelComplete;
  taskTypeLabelIncomplete: $taskTypeLabelIncomplete;
  bluesky: $bluesky;
  bluesky2: $bluesky2;
  lightGrey: $lightGrey;
  trellisBlue: $trellisBlue;
  trellisLightBlue: $trellisLightBlue;
  teal: $teal;
  patina: $patina;
  patinaWhite4: $patinaWhite4;
  greyBlue: $greyBlue;
  greyLightBlue: $greyLightBlue;
  cornflowerblue: $cornflowerblue;
  grey2: $grey2;
  grey3: $grey3;
  grey12: $grey12;
  grey13: $grey13;
  grey14: $grey14;
  grey23: $grey23;
  grey25: $grey25;
  pearlGrey: $pearlGrey;
  adherenceIntHistoryBorderGrey: $adherenceIntHistoryBorderGrey;
  timberWolf: $timberWolf;
  silverMist: $silverMist;
  deepBlue: $deepBlue;
  deepBlue2: $deepBlue2;
  valenciaRed: $valenciaRed;
  patinaWhite: $patinaWhite;
  redWhite: $redWhite;
  lightGreen: $lightGreen;
  offWhite2: $offWhite2;
  greySubRow: $greySubRow;
  orange: $orange;
  bismark: $bismark;
  rollingStone: $rollingStone;
  deepChestnutRed: $deepChestnutRed;
}
