@import '../../../lib/themes/colors.module.scss';

*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

[tabindex='-1']:focus {
  outline: none !important;
}

.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-select-dropdown--multiple {
  z-index: 15000;
}

.ant-select-tree {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: $trellisBlue;
  border-color: $trellisBlue;
  border: 1px solid $trellisBlue;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: $trellisBlue;
}

.ant-select-tree-checkbox-inner {
  border: 1px solid $trellisBlue;
}

.ant-select-selection--multiple .ant-select-selection__choice__content {
  margin-right: 5px;
}
.ant-select-open .ant-select-selection {
  border-color: $trellisBlue;
  border-right-width: 0px !important;
  box-shadow: 0 0 0 0 $white;
}

.ant-select-dropdown-menu-item-selected {
  background-color: $trellisBlue;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: $offWhite;
  border-radius: 16px;
  margin: 4px 2px;
  display: flex;
  align-items: center;
}

.ant-select-selection--multiple {
  border: none;
  box-shadow: none;
  background: $white;
  border-bottom: 1px solid $offWhite;
}

.ant-select-search__field__placeholder {
  color: $grey12;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(68, 72, 76, 0.26);
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove i {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-selection:hover {
  border-color: $trellisBlue;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}

.ant-select-tree
  li
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background: $offWhite;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: transparent;

  border-right-width: 0px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px transparent;
          box-shadow: 0 0 0 0px transparent;
  border-bottom: 1px solid $offWhite;
}

.slide-up-leave {
  display: none;
}